@import '/src/App.css';

.passwordRecoveryContainer {
  display: flex;
  background-image: url('../../assets/images/fondo_base_violeta-.png');
  background-size: cover;
}

.passwordRecoveryLSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 95vh !important;
  width: 50vw !important;
  padding: 4vh 0;
}

.passwordRecoveryTudatalogo {
  margin-top: 3vw;
  margin-left: 3vw;
  height: 9vh;
}

.passwordRecoveryLSideText {
  font-family: var(--quicksand-bold);
  font-size: 3vw;
  color: var(--white);
  margin-left: 3vw;
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.passwordRecoveryLSideBackContainer {
  font-family: var(--quicksand-bold);
  font-size: 2vw;
  color: var(--white);
  margin-top: 2vw;
  margin-left: 3vw;
  margin-bottom: 2vw;
}

.passwordRecoveryLSideBackLink,
.passwordRecoveryLSideBackLink:hover {
  font-family: var(--poppins-medium);
  font-size: 3vh;
  color: var(--white);
  text-decoration: none;
  position: absolute;
  top: 92vh;
}

.passwordRecoveryReturnArrow {
  width: 1.6vw;
  transform: rotate(90deg);
}

.passwordRecoveryRSideContainer {
  background-color: var(--white);
  background-size: cover;
  height: 100vh !important;
  width: 50vw !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6vw;
}

.passwordRecoveryRSideTopText {
  font-family: var(--poppins-light);
  font-size: 1.3vw;
  color: var(--dark-grey4);
  line-height: 3vh;
  width: 75%;
  margin-bottom: 0.5vw;
}

.passwordRecoveryRSideCodeMessage {
  font-family: var(--poppins-light);
  font-size: 1.1vw;
  margin: 4vh 0 0.2vh 0;
  line-height: 3vh;
  width: 100%;
  user-select: none;
  color: var(--white);
}

.passwordRecoveryRSideCorrectCode {
  color: var(--white);
}

.passwordRecoveryRSideWrongCode {
  color: var(--red);
}

.passwordRecoveryRSideCodeFormCOntainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.passwordRecoveryRSideCodeForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordRecoveryRSideInputsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.showPassButtonPasswordRecovery {
  display: flex;
  width: 2vw;
  position: relative;
  top: -38px;
  left: 35vw;
}

.passwordRecoveryEyeIcon {
  width: 2vw;
}

.passwordRecoveryRSideInput {
  height: 14vh !important;
  width: 5.5vw !important;
  border: 1px solid var(--dark-grey2);
  border-radius: 15px !important;
  text-align: center !important;
  font-family: var(--poppins-light) !important;
  font-size: 5vh !important;
  color: var(--dark-grey) !important;
}

.passwordRecoveryRsideCodeAdvice {
  font-family: var(--poppins-light);
  font-size: 1.3vw;
  color: var(--dark-grey2);
  margin-top: 1vh;
}

.passwordRecoveryRSideSendBtn,
.passwordRecoveryRSideSendBtn:hover,
.passwordRecoveryRSideSendBtn:focus {
  font-family: var(--poppins-bold);
  font-size: var(--button-font-size);
  border-radius: 13px;
  padding: 2vh 0;
  margin-top: 2vh;
  background-color: var(--orange);
  color: var(--white);
  border: 100px;
  width: 90%;
  text-decoration: none;
  text-align: center;
}

.passwordRecoveryRSideSendBtn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  width: 90%;
}

.setNewpasswordInput {
  height: 7vh;
  border-radius: 7px;
  font-family: var(--poppins-light);
  font-size: 3vh;
  color: var(--dark-grey);
  padding-left: 0.75rem;
  border: 1px solid var(--black);
  outline: none;
}

.setNewPasswordShowPassButton {
  width: 2vw;
  position: relative;
  bottom: 6.2vh;
  left: 33vw;
}

.setNewPasswordEyeIcon {
  width: 2.5vw;
  vertical-align: inherit;
}

.setNewPasswordFeedbackMessage {
  font-family: var(--poppins-light);
  color: var(--red);
  margin-top: -33px;
  font-size: .8vw;
}

.passwordRecoveryReturnParagraph,
.passwordRecoveryReturnParagraph:hover {
  font-family: var(--poppins-medium);
  font-size: 3vh;
  color: var(--white);
  text-decoration: none;
  margin-left: 3vw;
}
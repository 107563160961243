#SubCategories {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_base_violeta-.png);
    background-size: cover;
    width: 100%;
}

.SubCategoriesContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.SubCategoriesSearch {
    display: flex;
    justify-content: center;
}

.SubCategoriesSearch div {
    border-bottom: 1px solid var(--white);
}

.SubCategoriesSearchBtn {
    background: transparent;
    border: 0;
}

.SubCategoriesSearch img {
    width: 2vw;
}

.SubCategoriesSearch input {
    background: transparent;
    border: 0;
    color: var(--white);
    width: 40vw;
}

.SubCategoriesSearch input:focus {
    outline: none;
}

.SubCategoriesList {
    margin-top: 2vh;
}

.SubCategoriesListCategory {
    width: 75%;
    margin: 0 auto;
    padding-right: 1vw;
    overflow-y: auto;
    max-height: 70vh;
    font-family: var(--quicksand-bold);
}

.SubCategoriesListCategory h2 {
    color: var(--white);
    margin-bottom: 2vh;
}

.SubCategoriesListCategoryGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: var(--white);
    gap: 2vw;
    margin-bottom: 2vh;
}

.SubCategoriesFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.SubCategoriesFooterContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.SubCategoriesFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.SubCategoriesFooterContent div {
    display: flex;
    align-items: center;
}

.SubCategoriesFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.SubCategoriesFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.SubCategoriesFooterNextBtn img {
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.SubCategoriesFooterNextBtn:disabled {
    background: none;
    cursor: not-allowed;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
}

.SubCategoriesFooterNextBtn:disabled img {
    display: none;
}

.SubCategoriesSearchInput,
.SubCategoriesSearchInput::placeholder {
    font-family: var(--poppins-light);
    color: #FFF4E9;
    font-size: 1.8vw;
}
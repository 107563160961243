#SubCategoriesItems {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.SubCategoriesItemsContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.SubCategoriesItemsSearch {
    display: flex;
    justify-content: center;
}

.SubCategoriesItemsSearch div {
    border-bottom: 1px solid var(--white);
}

.SubCategoriesItemsSearchInput,
.SubCategoriesItemsSearchInput::placeholder {
    font-family: var(--poppins-light);
    color: #FFF4E9;
    font-size: 1.8vw;
}

.SubCategoriesItemsSearchBtn {
    background: transparent;
    border: 0;
}

.SubCategoriesItemsSearch img {
    width: 2vw;
}

.SubCategoriesItemsSearch input {
    background: transparent;
    border: 0;
    color: var(--white);
    width: 40vw;
}

.SubCategoriesItemsSearch input:focus {
    outline: none;
}

.SubCategoriesItemsList {
    width: 92%;
    margin: 0 auto;
    margin-top: 4vh;
    max-height: 65vh;
    overflow-y: auto;
}

.SubCategoriesItemsList::-webkit-scrollbar {
    width: 15px;
}

.SubCategoriesItemsList::-webkit-scrollbar-track {
    background: var(--dark-purple);
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
}

.SubCategoriesItemsList::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 20px;
}

.SubCategoriesItemsListCategory {
    width: 95%;
    margin: 0 auto;
    font-family: var(--quicksand-bold);
}

.SubCategoriesItemsListCategory h2 {
    color: var(--white);
    font-size: 1.8vw;
    margin-top: 2vh;
}

.SubCategoriesItemsListCategoryGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: var(--white);
    gap: 2vh 5vw;
    padding: 3vh 0;
}

.SubCategoriesItemsFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.SubCategoriesItemsFooterContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.SubCategoriesItemsFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.SubCategoriesItemsFooterContent div {
    display: flex;
    align-items: center;
}

.SubCategoriesItemsFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.SubCategoriesItemsFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.SubCategoriesItemsFooterNextBtn img {
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.SubCategoriesItemsFooterNextBtn:disabled {
    background: none;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
    cursor: not-allowed;
}

.SubCategoriesItemsFooterNextBtn:disabled img {
    display: none;
}
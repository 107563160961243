#CategoriesAge {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.CategoriesAgeContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.CategoriesAgeLogoBox img {
    width: 20vw;
}

.CategoriesAgeText {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.4vw;
    margin-bottom: 3vh;
    margin-top: -2vh;
}

.CategoriesAgeText p {
    width: 55%;
}

.CategoriesAgeGrid {
    width: 55%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh 1vw;
}

.CategoriesAgeFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.CategoriesAgeFooterContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CategoriesAgeFooterContent div {
    display: flex;
    align-items: center;
}

.CategoriesAgeFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.CategoriesAgeFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.CategoriesAgeFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesAgeFooterNextBtn img {
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.CategoriesAgeFooterNextBtn:disabled {
    background: none;
    cursor: not-allowed;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesAgeFooterNextBtn:disabled img {
    display: none;
}
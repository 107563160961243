.buyDBUploadReceipt {
  display: flex;
  flex-direction: column;
}

.buyDBUploadReceiptConfirmation {
  margin-bottom: 5vh;
  width: 100%;
  background: var(--light-grey);
  border-radius: 30px;
}

.buyDBUploadReceiptConfirmationHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 6vh;
}

.buyDBUploadReceiptConfirmationContent {
  display: flex;
  justify-content: space-between;
}

.buyDBUploadReceiptConfirmationHeader {
  border-bottom: solid 2px var(--dark-purple);
  padding: 0 2vw;
}

.buyDBUploadReceiptTitle {
  font-family: var(--poppins-bold);
  color: var(--dark-purple);
  font-size: 1.5vw;
}

.buyDBUploadReceiptConfirmationContent {
  padding: 0 2vw;
  padding-top: 2.5vh;
  min-height: 15vh;
  width: 100%;
}

.buyDBUploadReceiptConfirmationParagraph {
  font-family: var(--poppins-medium);
  color: #5E5E5E;
  font-size: 1.5vw;
}

.buyDBUploadReceiptNextBtn {
  background-color: var(--orange);
  padding: 1.5vh 2.5vw;
  font-family: var(--poppins-medium);
  color: var(--white);
  font-size: var(--button-font-size);
  border-radius: 0.8vw;
  border: none;
  margin-left: auto;
}

.buyDBUploadReceiptModalIcon {
  width: 2.5vw;
  height: 2.5vw;
  color: red;
  margin: 2vh 0;
}

.buyDBUploadReceiptPayButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 2vw;
}

.paymentDescription-uploadFile {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.paymentDescription-VoucherName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#voucherUpload {
  display: none;
}

.paymentDescription-btn {
  cursor: pointer;
  background-color: var(--orange);
  color: var(--white);
  font-family: var(--poppins-bold);
  font-size: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 7vh;
  margin-bottom: .5vh;
  border: 0;
  border-radius: 15px;
}

.paymentDescription-btn img {
  width: 1.7vw !important;
  margin-right: 1vw;
}

.paymentDescription-btn p {
  font-family: var(--poppins-bold);
}

.paymentDescriptionSend-btn {
  cursor: pointer;
  background-color: var(--orange);
  color: var(--white);
  font-family: var(--poppins-bold);
  font-size: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 7vh;
  margin-bottom: .5vh;
  border: 0;
  border-radius: 15px;
}

.paymentDescriptionSend-btn img {
  width: 5vw;
  margin-left: .5vw;
  margin-bottom: .2vh;
}

.paymentDescriptionSend-btn:disabled {
  background-color: var(--white);
  border: solid 1px var(--dark-grey3);
  color: var(--dark-grey2);
  font-size: 1.5vw;
  cursor: not-allowed;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.CategoriesCard{
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid var(--white);
    height: 26vh;
    position: relative;
    border-radius: 15px;
    background-color: var(--dark-purple);
}

.CategoriesCard img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.CategoriesCardKidImg{
    object-position: 0 -9vh;
}

.CategoriesCardTeenImg{
    object-position: 0 -8.5vh;
}

.CategoriesCardAdultImg{
    width: 105%;
    object-position: -.5vw -6.5vh;
}

.CategoriesCardSeniorImg{
    width: 105%;
    object-position: -.5vw -8vh;
}

.CategoriesCardMaleImg{
    width: 95%;
    object-position: 0 -3.5vh;
}

.CategoriesCardFemaleImg{
    object-position: 0 -7vh;
}

.CategoriesCardOtherImg{
    width: 105%;
    object-position: -.5vw -7vh;
}

.CategoriesCardAnyImg{
    width: 105%;
    object-position: -.5vw -14vh;
}

.CategoriesCardSpan{
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1vw;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: .5vh 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.isChecked{
    background-color: var(--light-purple);
}

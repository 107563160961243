@import '/src/App.css';

.mainContainerNotFound{
    width: 100vw;
    height: 100vh !important;
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
}

.containerNotFound {
    height: 85vh;
    margin: 0 auto;
    width: 95vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.logoAppNotFound{
    width: 20vw;
    height: 14vh;
    /* background-color: var(--orange); */
    padding: 1rem 2rem;
}

.mainTitleNotFound {
    font-family: var(--poppins-medium);
    font-size: 3.5vw;
    color: var(--white);
}

.subContainerNotFound1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.paragraphNotFound1 {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--white);
}

.paragraphNotFound2 {
    font-family: var(--poppins-medium);
    font-size: 1vw;
    color: var(--white);
}

.subContainerNotFoundImg{
    width: 30vw;
}

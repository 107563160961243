
@import '/src/App.css';

.mainContainerBaseCompradores {
    background-size: cover;
    height: 100vh;
    min-height: 100%;
    background-repeat: no-repeat;
    background-Image: url('../../assets/images/fondo_base_violeta-.png')
}

.colMenuCompradores {
    text-align: center;
    padding: 0 !important;
    width: 21% !important;
    height: 100vh;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}

.subContainerMenuCompradores1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60%;
}

.mainLogoContainer {
    display: flex;
    flex-direction: column;
    margin: 3vh auto;
    align-items: flex-end;
}

.logoParagraph {
    color: var(--white);
}

.subContainerMenu2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 30%;
}

.logoutContainer {
    color: var(--white);
    display: flex;
    padding: 1.5vh 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    text-decoration: none;
    font-family: var(--quicksand-bold);
    font-size: 1.8vw;
    cursor: pointer;
}

.logoutContainer:hover {
    color: var(--white);
    background: rgba(255, 93, 0, 0.7);
    cursor: pointer;
}

.logoutContainer>img {
    width: 2.5vw;
    margin-left: -0.7vw;
}

.logoutContainer p{
    font-size: 1.5vw;
    margin: 0;
}

.logoutText {
    margin-left: -0.7vw !important;
}

.colDatos {
    background: #EEEEEE;
    width: 79% !important;
    height: 100vh;
    min-height: 100%;
    margin-right: 0;
    padding: 0 !important;
}

.logoApp {
    width: 16vw;
    margin-bottom: 2vh;
}

.logoParagraph {
    font-size: 0.8vw;
}


.navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 1.2vh 0;
    text-decoration: none;
}

.item-selected:hover {
    background: rgba(255, 93, 0, 0.7);
}

.item-selected {
    background: rgba(255, 93, 0, 0.85);
}

.iconoPersonaBaseC {
    width: 15%;
}

.paragraphSolicitudes,
.paragraphValidaciones,
.paragraphUsuarios,
.paragraphPQRS,
.paragraphAjustes {
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin: 0;
    color: #FFFFFF;
    width: 50%;
    text-align: left;
    line-height: 120%;
}

.subContainer {
    background: #FFFFFF;
    border-radius: 35px;
    height: 93vh;
    margin: 2rem 1rem;
    overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btnStartTour {
    position: absolute;
    top: 40px;
    right: 40px;
    background-image: url('../../assets/icons/icon_touarpp-02.svg');
    background-size: cover;
    width: 6vh;
    height:6vh;
    border: none;
    background-color: transparent;
    z-index: 3;
}

.btnStartTour:hover {
    position: absolute;
    top: 40px;
    right: 40px;
    background-image: url('../../assets/icons/icon_touarpp-01.svg');
    background-size: cover;
    width: 6vh;
    height:6vh;
    border: none;
    background-color: transparent;
    z-index: 3;
}

.btnStartTourHidden {
    display: none;
}

.btnStartTourHidden:hover {
    display: none;
}

@media (min-width: 576px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 100vw !important;
    }
}
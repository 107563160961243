.successfulTransaction {
  height: 100vh;
  background-image: url('../../../../assets/images/fondo_general_naranja.png');
  background-size: cover;
  padding: 10vh 5vw;
  color: var(--white);
}

.successfulTransactionLogo {
  height: 10vh;
  margin-bottom: 10vh;
}

.successfulTransactionPrincipalContent {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
}

.successfulTransactionPrincipalMessage {
  backdrop-filter: blur(20px);
  padding: 5vh 0;
  margin: 0 -5vw;
  border-top: solid 1px var(--white);
  border-bottom: solid 1px var(--white);
}

.successfulTransactionTitle {
  font-family: var(--quicksand-bold);
  font-size: 4.2vw;
}

.successfulTransactionPurchasedPlan {
  font-family: var(--quicksand-bold);
  font-size: 2vw;
}

.successfulTransactionSecondaryMessage {
  width: 48vw;
  font-family: var(--poppins-light);
  font-size: 1.6vw;
  align-self: center;
  line-height: 2.2rem;
}

.successfulTransactionTuData {
  font-family: var(--poppins-medium);
}

.successfulTransactionNextButton {
  margin: 0 auto;
  margin-bottom: 10vh;
  padding: 1.5vh 0;
  font-family: var(--poppins-medium);
  font-size: var(--button-font-size);
  color: var(--white);
  background-color: var(--orange);
  border: none;
  width: 40%;
  border-radius: 1vw;
}
@import '/src/App.css';

#BaseLayout {
    position: relative;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pageContent {
    height: 100%;
    margin: 0 1vw;
    margin-top: 2vh;
}

.BaseLayoutHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2vw;
    width: 60vW;
    gap: 2vw;
}

.BaseLayoutHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-light) !important;
    margin-bottom: -4px;
    text-decoration: none;
}

.HeaderActive {
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
}

.hrBaseLayout {
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    /* margin: 0.1rem 0 !important; */
    margin: 0 !important
}

.conditionsPoliciesFooterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 2px 6px 25px #00000029;
    border: none;
    border-radius: 15px;
    background: var(--orange);
    padding: 1.5vh 1.3vw;
    font-family: var(--poppins-light);
    color: var(--white);
    font-size: var(--button-font-size);
    margin-left: 55vw;
    text-decoration: none;
}

.conditionsPoliciesFooterButtonImg {
    width: 1.8vw;
    margin-right: 0.8vw;
}
.contendFAQ {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../assets/images/fondo_general_naranja.png");
    background-size: cover;
}

.FAQcontainer {
    background-color: var(--light-purple-transparent);
    backdrop-filter: blur(10px);
    border-radius: 0.5vw;
    width: 60%;
    height: 75vh;
    margin-top: 8vw;
    padding: 2.5vw 5vw 0 5vw;
}

.labelFAQ {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--white);
    margin-bottom: 1vw;
}

.contendAccordionFAQ {
    max-height: 62vh;
    overflow-y: scroll;
    border-radius: 0.5vw;
}

.accordionBody {
    font-family: var(--poppins-light);
    color: var(--dark-grey7);
    font-size: 0.8vw;
    padding: 1vw 2vw;
}

.accordionItem {
    --bs-accordion-btn-focus-box-shadow: #FFF;
}

.accordionItem .accordion-button {
    color: var(--dark-grey4) !important;
    font-family: var(--poppins-medium);
    font-size: 0.9vw;
    height: 7vh;
    background: var(--white);
    padding: 0 2vw;
}

.accordionItem .accordion-button::after {
    background-image: url(../../../../assets/icons/arrowDown.svg) !important;
}
@import '/src/App.css';

.companyRegistration2MainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin: 0;
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
    overflow: hidden;
}

.companyRegistration2SubContainer1 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;
    padding: 4vh 3vw;
    justify-content: space-between;
}

.companyRegistration2ImgPragraphContainer {
    display: flex;
    flex-direction: column;
}

.companyRegistration2SubContainer1Logo {
    height: 10vh;
    margin-top: 8vh;
    align-self: flex-start;
}

.companyRegistration2Container1Paragraph {
    font-family: var(--quicksand-bold);
    color: var(--white);
    font-size: 2vw;
    margin-top: 4vh;
}

.companyRegistration2Container1H2 {
    width: 40vw;
    font-family: var(--quicksand-bold);
    font-size: 3vw;
    color: var(--white);
}

.compayRegistrationReturnParagraph,
.compayRegistrationReturnParagraph:hover {
    font-family: var(--poppins-medium);
    font-size: 3vh;
    color: var(--white);
    text-decoration: none;
    margin-bottom: 4vh;
}

.companyRegistration2SubContainer2 {
    background-color: var(--white);
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50vw;
    padding: 6vh 6vw;
    justify-content: space-between;
    align-items: flex-end;
    overflow: auto;
}

.companyRegistration2LogInLink1 {
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    text-decoration: none;
    font-size: 1vw;
}

.companyRegistration2Inputs {
    margin-top: 5vh;
}

.companyRegistration2Inputs,
.companyRegistration2InputsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.companyRegistration2Inputs small {
    color: red;
    font-size: 1vw;
    font-family: var(--poppins-light);
}

.showPassButtonInicioSesion {
    display: flex;
    width: 2vw;
    top: 1.7vh;
    position: absolute;
    left: 35vw;
}

.companyRegistration2EyeIcon {
    width: 2vw;
}

.companyRegistration2Container2FormPassword {
    position: relative;
    margin: 0;
    height: 6vh;
}

.companyRegistration2LogInLink2 {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1vw;
}

.companyRegistration2Container2Form {
    border: solid 2px var(--dark-grey);
    border-radius: 0.4vw;
    width: 38vw;
    min-height: 0 !important;
    padding: 1vh !important;
    font-size: 1.2vw;
    font-family: var(--poppins-light);
}

.companyRegistration2InputButtonContainer {
    width: 38vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.companyRegistration2FileCustomButton {
    width: fit-content;
    padding: 1vh 2vw;
    background-color: var(--orange);
    color: var(--white);
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.companyRegistration2fileName {
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    font-size: 0.9vw;
    margin-left: 1vw;
    /* border-bottom: 1px solid black;
    width: 25vw; */
}

.hidden {
    display: none;
}

.companyRegistration2ContinueButton {
    font-family: var(--poppins-medium);
    font-size: 1.6vw;
    border-radius: 13px;
    padding: 1vh 0;
    margin-bottom: 3vh;
    margin-left: 0;
    margin-right: 0;
    background-color: var(--orange);
    color: var(--white);
    border: none;
    width: 100%;
}

.form-floating label {
    font-family: var(--poppins-light);
    font-size: 1vw;
    color: var(--dark-grey3);
}

.companyRegistrationForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.idInputsContainer {
    display: grid;
    grid-template-columns: 25% 74%;
    column-gap: 0.5vw;
}

.idNumInput,
.expeditionCityInput {
    border: solid 2px var(--dark-grey);
    border-radius: 0.4vw;
    min-height: 0 !important;
    padding: 1vh !important;
    font-size: 1.2vw;
    font-family: var(--poppins-light);
}

.idNumInput:disabled,
.expeditionCityInput:disabled {
    cursor: not-allowed;
}

.idNumAndExpeditionCityErrorMessages {
    margin-top: -0.7vw;
}

.idNumAndExpeditionCityErrorMessages .showSpanPass+.incorrectFormat {
    margin-left: 27%;
}

.idNumAndExpeditionCityErrorMessages .incorrectFormat+.incorrectFormat {
    margin-left: 10%;
}
@import '/src/App.css';

.mainContainerLoopayCode {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1vh 3vw;
}

.firstParagraphLoopayCode, .secondParagraphLoopayCode {
  font-family: var(--poppins-light);
  color: var(--dark-grey);
  font-size: 1.5vw;
}

.firstParagraphSpanLoopayCode {
  font-family: var(--poppins-bold);
}

.formContainerLoopayCode {
  height: 45vh;
  width: 65vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.inputContainerLoopayCode {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 66vw;
}

.inputCodeLoopayCode {
  height: 22vh !important;
  width: 10vw !important;
  border-radius: 30px !important;
  background-image: none !important;
  text-align: center !important;
  margin: 0 0.5vw !important;
  padding-right: 0 !important;
  font-family: var(--poppins-light) !important;
  font-size: 10vh !important;
  color: var(--dark-grey) !important;
  z-index: 1 !important;
}

.invalidFeedback {
  margin: 2vh 1vw;
  display: block;
  font-family: var(--poppins-medium);
  color: var(--white);
  user-select: none;
  font-size: 1vw;
}

.invalidFeedbackVisible {
  color: #FF0000;
}

.checkBoxesContainerLoopayCode {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh 0;
}

/* .chkBoxLoopayCode {
  border-color: var(--orange);
  margin-right: 0.5vw;
} */

.chkBoxLoopayCode {
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 18px;
  height: 18px;
  border: 1px solid var(--orange);
  margin-right: 0.5vw;
}

.chkBoxLoopayCode:checked {
  background-color: var(--orange);
}

.chkBoxLoopayCode:focus {
  border-color: var(--orange);
}

.chkBoxLoopayCode:disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--dark-grey2);
  background-image: none;
  cursor: not-allowed;
}

.chkBoxLoopayCodeText {
  color: #313131;
}

.termsConditionsLblLoopayCode,
.politicsUseLblLoopayCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--poppins-regular);
  font-size: 1.3vw;
  width: fit-content;
}

.disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--poppins-regular);
  font-size: 1.3vw;
  color: var(--dark-grey);
  width: fit-content;
}

.termsConditionsLinkLoopayCode, .politicsUseLinkLoopayCode {
  font-family: var(--poppins-medium);
  color: var(--orange);
  font-size: 1.3vw;
  margin-left: 0.3vw;
}

.termsConditionsLinkLoopayCode:hover, .politicsUseLinkLoopayCode:hover {
  color: var(--orange);
}

.btnContainerLoopayCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40vw;
  justify-content: space-around;
  font-size: var(--button-fontSize);
}

.lnkLoopayCode, .lnkLoopayCode:hover {
  font-family: var(--poppins-bold);
  color: var(--orange);
  font-size: 1.8vw;
  display: block;
}

.lnkLoopayCodeVisible {
  display: none;
}

.btnLoopayCode, .btnLoopayCode:hover, .btnLoopayCode:focus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16vw;
  height: 7vh;
  background: var(--orange);
  border-radius: 13px;
  color: var(--white);
  font-family: var(--poppins-bold);
  font-size: var(--button-fontSize);
  text-decoration: none;
  border: none;
}

.btnLoopayCode:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.hrLoopayCode {
  width: 70vw;
  font-weight: bold;
  align-self: center;
  border: 1px solid var(--dark-grey2);
}

.tryAnotherMethodParagraphLoopayCode, .tryAnotherMethodParagraphLoopayCode:hover {
  font-family: var(--poppins-regular);
  font-size: 1vw;
  color: var(--orange);
  text-decoration: none;
}

.sendCodeBtn,
.sendCodeBtn:hover,
.sendCodeBtn:focus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16vw;
  height: 7vh;
  background: var(--orange);
  border-radius: 13px;
  color: var(--white);
  font-family: var(--poppins-bold);
  font-size: 1.4vw;
  text-decoration: none;
  border: none;
}

.sendCodeBtn:disabled {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}
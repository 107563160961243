#CategoriesGeneral {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.CategoriesGeneralContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.CategoriesGeneralLogoBox img {
    width: 20vw;
}

.CategoriesGeneralText {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.4vw;
    margin-bottom: 3vh;
    margin-top: -2vh;
}

.CategoriesGeneralText p {
    width: 48%;
}

.CategoriesGeneralGrid {
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vh 1vw;
}

.CategoriesGeneralCard {
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid var(--white);
    height: 26vh;
    position: relative;
    border-radius: 15px;
    background-color: var(--dark-purple);
}

.CategoriesGeneralCard img {
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.CategoriesCardHealthImg {
    object-position: 0 -6vh;
}

.CategoriesCardGastronomyImg {
    object-position: 0 -19vh;
}

.CategoriesCardGastronomyImg {
    object-position: 0 -19vh;
}

.CategoriesCardTechnologyImg {
    object-position: 0 -11vh;
}

.CategoriesGeneralCard span {
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1vw;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: .5vh 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.CategoriesGeneralCard:hover span {
    background-color: var(--light-purple);
}

.CategoriesGeneralFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.CategoriesGeneralFooterContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CategoriesGeneralFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.CategoriesGeneralFooterContent div {
    display: flex;
    align-items: center;
}

.CategoriesGeneralFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.CategoriesGeneralFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesGeneralFooterNextBtn img {
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.CategoriesGeneralFooterNextBtn:disabled {
    background: none;
    cursor: not-allowed;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesGeneralFooterNextBtn:disabled img {
    display: none;
}
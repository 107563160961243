.banner {
    background-color: var(--orange);
    min-height: 25vh;
    border-radius: 35px;
    display: flex;
    align-items: center;
    gap: 3vw;
    padding: 0 3vw;
}

.bannerImgContainer {
    width: 9vw;
    height: 9vw;
    border: solid 3px var(--white);
    border-radius: 50%;
    padding: 1.1vw;
}

.logoImg {
    position: relative;
    height: 8vw;
    width: 8vw;
    margin-top: -0.75vw;
    margin-left: -0.75vw;
    border-radius: 50%;
    object-fit: cover;
}

.bannerEnterpriseName {
    font-family: var(--quicksand-bold);
    color: var(--white);
    font-size: 3.5vw;
}
.DataBaseButtonBox{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3vh;
}

.DataBaseButton{
    border: 0;
    background-color: var(--orange); 
    color: var(--white);
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 25vw;
    height: 10vh; */
    /* border-radius: 30px; */
    border-radius: 1.6vw;
    padding: 1.8vh 2vw;
    box-shadow: 2px 6px 25px #00000029;
}

.DataBaseButton img{
    width: 2.8vw;
    margin-right: 1vw;
}

.DataBaseTable{
    width: 106%;
    margin-left: -2vw;
    border-collapse: separate;
    border-spacing: 0 2vh;
}

.DataBaseTableHeader{
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1vw;
    display: flex;
    justify-content: space-between;
    padding: 2vh 6vw;
}

.DataBaseTableHeader p{
    width: 13vw;
    text-align: center;
}

.DataBaseTableBody{
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1vw;
    border-spacing: 20px;
    height: 67vh;
    width: 78vw;
    overflow-y: auto;
}

.DataBaseTableBodyRow{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 3.5vh 3.5vw;
    margin: 2vh 1vw 2vh 2vw;
    background-color: var(--light-grey);
    border-radius: 20px;
}

.DataBaseStateContainer {
    width: 13vw;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.DatabaseInfo {
    font-family: var(--poppins-medium);
    color: var(--light-grey3);
    font-size: 1vw;
}

.DataBaseStateIndicator {
    width: 0.8vw;
    height: 0.8vw;
    border: 1px solid var(--dark-grey3);
    border-radius: 50%;
    margin-left: 0.5vw;
}

.DataBaseStateIndicatorActiveColor {
    background: var(--green2);
    margin-right: 1vw;
}

.DataBaseStateIndicatorInactiveColor {
    background: var(--red2);
}

.DataBaseStateIndicatorPendingColor{
    background-color: var(--yellow);
}

.DataBaseTableBodyRow p{
    width: 14vw;
    text-align: center;
}

.DataBaseTable tbody td{
    padding: 2vh 0;
}

.DataBaseTableRowButton {
    background-color: var(--white);
    display: block;
    width: 100%;
    border: none;
}

.noBDText{
    font-size: 1.5vw;
    margin-left: 4vw;
    margin-top: 5vh;
    color: var(--dark-grey3);
}

.wannaBuyText{
    color: var(--orange);
    font-family: var(--poppins-bold);
    text-decoration: underline;
}
.buyDBResume {
  display: flex;
  gap: 2vw;
  margin-bottom: 3vh;
  width: 100%;
}

.buyDBResumeImgContainer {
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 0;
}

.SimpleBG{
  background-color: var(--dark-purple);
}

.SpecializedBG{
  background-color: var(--light-purple);
}

.buyDBResumeImg {
  width: 10vw;
  margin: 0 5vw;
  margin-bottom: 2.5vh;
}

.buyDBResumeImgTitle {
  font-family: var(--quicksand-bold);
  font-size: 1.5vw;
  margin-bottom: 1vh;
}

.buyDBResumePlanType {
  font-family: var(--poppins-light);
  font-size: 1.1vw;
}

.buyDBResumeImgTitle,
.buyDBResumePlanType {
  color: var(--white);
  text-align: center;
  line-height: 2vh;
}

.buyDBResumeDescriptionTitle {
  font-family: var(--poppins-bold);
  color: var(--dark-purple);
  font-size: 1.5vw;
}

.buyDBResumeDescription {
  font-family: var(--poppins-regular);
  color: #5E5E5E;
  font-size: 1.5vw;
}
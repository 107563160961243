@import '/src/App.css';

.home {
  background-image: url(../../../../assets/images/fondo_general_naranja.png);
  background-size: cover;
  position: relative;
  height: 100vh;
}

.homeContent {
  width: 70vw;
  height: 100vh;
  padding-top: 6vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.homeTitle {
  font-size: 11vw;
  margin-top: 0;
  color: var(--white);
  font-family: var(--quicksand-bold);
  line-height: 11vw;
  z-index: 1;
}

.homeSubtitle {
  font-size: 4vw;
  margin-top: 0;
  color: var(--white);
  font-family: var(--quicksand-bold);
  line-height: 5vw;
  z-index: 1;
}

.homeDescription {
  font-size: 1.7vw;
  color: var(--white);
  font-family: var(--quicksand-bold);
  width: 60%;
  margin-top: 5vh;
}

.homeImage {
  position: absolute;
  bottom: 0vh;
  right: 14vw;
  width: 38vw;
  height: 45vw;
}

@media screen and (max-width: 1280px) {
  .homeImage {
    position: absolute;
    width: 40vw;
    height: 90vh;
  } 
}
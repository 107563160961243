#YourChoices {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.YourChoicesContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.YourChoicesBackBox {
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 2vw;
    display: flex;
    align-items: center;
}

.YourChoicesBackBox a {
    color: var(--white);
    text-decoration: none;
}

.YourChoicesBackBox img {
    width: 1.5vw;
    rotate: 90deg;
    margin-right: .3vw;
}

.YourChoicesBox {
    height: 65vh;
    margin-top: 3vh;
    background-color: var(--dark-purple);
    border: 1px solid var(--white);
    border-radius: 20px;
    padding: 4vh 4vw;
    color: var(--white);
}

.YourChoicesBoxScroll {
    width: 100%;
    height: 56vh;
    overflow-y: auto;
}

.YourChoicesBoxScroll::-webkit-scrollbar {
    width: 15px;
}

.YourChoicesBoxScroll::-webkit-scrollbar-track {
    background: var(--dark-aside-purple);
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
}

.YourChoicesBoxScroll::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 20px;
}

.YourChoicesBoxCategory h2 {
    font-family: var(--poppins-light);
    font-size: 1.7vw;
    margin-bottom: 3vh;
}

.YourChoicesBoxCategory article {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 19vw;
    height: 10vh;
    padding: 3vh 1vw;
    background-color: var(--orange);
    font-family: var(--quicksand-bold);
    font-size: 1.3vw;
    border-radius: 20px;
    border: 1px solid var(--white);
}

.YourChoicesBoxCategorySubCatsSelected {
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
    margin-bottom: 3vh;
}

.YourChoicesBoxCategorySubCat button {
    background-color: transparent;
    border: 0;
    padding-left: .5vw;
}

.YourChoicesBoxCategorySubCat img {
    width: 2.5vw;
}

.YourChoicesBoxCategorySubCatEmpty {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 1.5vw;
    font-family: var(--poppins-light);
    color: var(--dark-grey);
}

.YourChoicesFooter {
    display: flex;
    justify-content: space-between;
    color: var(--white);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin-top: auto;
}

.YourChoicesFooter a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.YourChoicesFooterContent div {
    display: flex;
    align-items: center;
}

.YourChoicesFooterContentlside img {
    width: 2vw;
    margin-right: .5vw;
}

.YourChoicesFooterContentrside img {
    width: 2vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.orange {
    color: var(--orange);
}

.yourChoicesContinueBuyBtn {
    background-color: transparent;
    border: none;
}

.yourChoicesContinueBuyBtn:disabled {
    cursor: not-allowed;
    color: var(--dark-grey);
}

.yourChoicesContinueBuyBtn:disabled span {
    color: var(--dark-grey);
}

.yourChoicesContinueBuyBtn:disabled img {
    display: none;
}

.yourChoicesContinueBuyTextBtn {
    color: white;
    font-family: var(--poppins-medium);
}
@import '/src/App.css';

.landingPage {
  width: 100vw;
  height: 100vh !important;
}

.landingPageNav {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.landingPageLogo {
  width: 26vw;
  height: 14vh;
  background-color: var(--orange);
  padding-left: 6rem;
}

.landingPageNavButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  background-color: var(--orange);
  width: 74vw;
  height: 14vh;
  padding: 2rem 6rem;
  gap: 8rem;
}

.landingPageNavBtn {
  font-family: var(--poppins-medium);
  text-decoration: none;
  color: var(--white);
  font-size: 1.5vw;
}

.navChecked{
  text-decoration: underline;
}

.landingPageNavLoginBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18vw;
  height: 8vh;
  font-family: var(--poppins-bold);
  font-size: 1.5vw;
  text-decoration: none;
  color: var(--white);
  background-color: var(--light-purple);
  border: none;
  border-radius: 13px;
  margin-top: .1vmax;
}

.landingPageNavLoginBtn:hover {
  color: var(--white);
}
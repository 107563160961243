.account {
  margin: 0 -2vw;
  margin-top: -3vh;
}

.accountNav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2vw;
  width: 60vW;
  gap: 1vw;
}

.accountNavItem {
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  font-family: var(--poppins-medium) !important;
  margin-bottom: -4px;
}

.accountNavItemActive {
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  max-height: 9vh;
}

.accountHr {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: .50 !important;
  margin: 0 !important
}

.accountCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  margin: 2vw;
}
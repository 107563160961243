#CategoriesLocation {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.CategoriesLocationContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.CategoriesLocationLogoBox img {
    width: 20vw;
}

.CategoriesLocationText {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.4vw;
    margin-bottom: 3vh;
    margin-top: -2vh;
}

.CategoriesLocationText p {
    width: 55%;
}

.CategoriesLocationArea {
    display: flex;
}

.CategoriesLocationSelects {
    width: 40%;
}


.CategoriesLocationSelect {
    color: var(--dark-grey);
    font-family: var(--quicksand-bold);
    font-size: 1.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
    width: 30vw;
    margin: 0 auto;
    margin-bottom: 2vh;
    padding: 0 2vw;
    height: 10vh;
    border-radius: 1.5vw;
}

.CategoriesLocationSelect:hover {
    cursor: pointer;
}

.CategoriesLocationOption {
    font-family: var(--quicksand-regular);
    color: var(--dark-grey6);
}

.CategoriesLocationCountryImage {
    width: 60%;
    display: flex;
    justify-content: center;
}

.CategoriesLocationCountryImage img {
    width: 20vw;
}

.CategoriesLocationFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.CategoriesLocationFooterContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CategoriesLocationFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.CategoriesLocationFooterContent div {
    display: flex;
    align-items: center;
}

.CategoriesLocationFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.CategoriesLocationFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesLocationFooterNextBtn img {
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.CategoriesLocationFooterNextBtn:disabled {
    background: none;
    cursor: not-allowed;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesLocationFooterNextBtn:disabled img {
    display: none;
}
@import '/src/App.css';

.passwordChangeFormContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.passwordChangeInputContainer {
  width: 44vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2vh;
}

.passwordChangeInputLabel {
  font-family: var(--poppins-light);
  color: var(--dark-grey);
  font-size: 1.5vw;
  margin-bottom: 0;
  margin-top: 2vh;
}

.passwordChangeInput {
  height: 9vh;
  border-radius: 15px;
  box-shadow: inset 6px 8px 25px #00000029;
  font-family: var(--poppins-light);
  font-size: 3vh;
  color: var(--dark-grey);
  padding-left: 0.75rem;
  border: none;
  outline: none;
}

.passwordChangeEyeIcon {
  width: 2.5vw;
  vertical-align: inherit;
}

.passwordProgressBar {
  width: 100%;
  height: 6px;
  border-radius: 5px;
  margin-top: -15px;
  margin-bottom: 20px;
}

.passwordProgressBar::-webkit-progress-bar {
  width: 100%;
  border-radius: 5px;
  background-color: #cdcdcd;
}

.passwordProgressBar::-webkit-progress-inner-element {
  border-radius: 5px;
}

.passwordProgressBarWeak::-webkit-progress-value {
  background-color: var(--red);
  border-radius: 5px;
}

.passwordProgressBarMedium::-webkit-progress-value {
  background-color: var(--yellow);
  border-radius: 5px;
}

.passwordProgressBarStrong::-webkit-progress-value {
  background-color: var(--green);
  border-radius: 5px;
}

.passwordChangeShowPassButton {
  width: 2vw;
  position: relative;
  bottom: 6.5vh;
  left: 40vw;
}

.passwordChangeBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33vw;
}

.passwordChangeSubmitButton {
  width: 100%;
  background: var(--orange);
  padding: 1vh;
  border: none;
  border-radius: 0.67vw;
  color: var(--white);
  font-family: var(--poppins-medium);
  font-size: 1.75vw;
}

.passwordChangeSubmitButton:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.passwordChangeFeedbackMessage {
  font-family: var(--poppins-light);
  color: var(--red);
  margin-top: -5px;
  font-size: .8vw;
}
@import '/src/App.css';

.contendContactanos {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10vw;
    justify-content: center;
    background-image: url("../../../../assets/images/fondo_general_naranja.png");
    background-size: cover;
}

.labelTextContactate {
    color: var(--white);
    font-size: 4vw;
    margin-left: -5rem;
    font-family: var(--quicksand-bold);
    text-align: left;
    line-height: 1;
}

.constendFormsContactanos {
    padding: 2vh .5vw;
    width: 30vw;
    margin-left: 10rem;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--light-purple);
    backdrop-filter: blur(19.5px);
    -webkit-backdrop-filter: blur(19.5px);
    border-radius: 20px;
    border: 1px solid var(--black-transparent)
}

.formContactanos {
    height: 5vh;
    color: var(--black) !important;
    font-size: 15px !important;
    font-family: var(--poppins-light);
    padding: 1rem;
}

.formContactanos:focus {
    box-shadow: none;
}

.longGeneralFieldsContact {
    width: 85%;
}

.longGeneralFields2Contact {
    width: 85%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px !important;
}

.labelForm {
    color: var(--white);
    font-family: var(--poppins-regular);
    font-size: 1.0vw;
    margin-bottom: 1px !important;
    margin-top: 5px !important;
}

.textAreaContactanos {
    color: var(--black) !important;
    font-family: var(--poppins-light);
    font-size: 1vw !important;
    height: 20vh;
}

.textAreaContactanos:focus {
    box-shadow: none;
}

.textAreaContactanos::placeholder {
    font-size: 1vw !important;
}

.btnContactanos,
.btnContactanosDisabled {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    width: 15vw;
    height: 7vh;
    background-color: var(--orange);
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--white);
    border: none;
    border-radius: 13px;
}

.btnContactanos:disabled,
.btnContactanosDisabled,
.btnContactanosDisabled:hover {
    background-color: var(--light-purple);
    border: solid 0.1vw var(--white);
    cursor: not-allowed !important;
}

.autorizationContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1vw;
    margin-top: 5%;
}

.autorizationInput {
    width: 2vw;
    height: 2vw;
}

.autorizationText {
    color: var(--white);
    font-family: var(--poppins-regular);
    font-size: 1vw;
}

.subjectCounter {
    font-family: var(--poppins-light);
    color: var(--white);
    text-align: end;
    width: 100%;
}
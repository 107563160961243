.DataBaseDetailTitle {
    display: flex;
    align-items: center;
    gap: 5vw;
    margin: 6vh 1.2vw 2vh;
}

.DataBaseDetailTitleText{
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
    margin-bottom: 0;
}

.DataBaseDetailTitleStatusDb{
    display: flex;
    align-items: center;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.DataBaseDetailTitleStatusDbState{
    color: var(--light-purple);
    margin-right: 1vw;
}

.DataBaseDetailTitleStatusDbValue{
    color: var(--dark-grey3);
    margin-right: 1vw;
}

.DataBaseDetailTopContent {
    display: flex;
    background-color: var(--light-grey);
    border-radius: 2vw;
    height: 33vh;
}

.DataBaseDetailParagraph {
    font-size: 1.5vw;
    padding: 2vw;
    flex: 1;
    max-width: 46vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.DataBaseDetailParagraph span {
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
}

.DataBaseDetailDueDate {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.DataBaseDetailInfo {
    display: flex;
    gap: 1vw;
}

.DataBaseDetailBotContent {
    display: flex;
    align-items: center;
    height: 20vh;
    font-size: 1.5vw;
    color: var(--dark-grey4);
    padding: 0 2vw;
    line-height: 4vh;
}

.DataBaseDetailBotContent p {
    font-family: var(--poppins-regular);
}

.DataBaseDetailBotContent span {
    font-family: var(--poppins-bold);
}

.DataBaseDetailBoxButton {
    display: flex;
    justify-content: flex-end;
}

.DataBaseDetailButton {
    position: absolute;
    z-index: 2;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    background-color: var(--orange);
    border: 0;
    color: var(--white);
    width: 25vw;
    height: 7vh;
    border-radius: 10px;
}

.DataBaseDetailButton:disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
}

.DataBaseDetailButton:hover {
    cursor: pointer;
}

.DataBaseDetailButtonHidden {
    display: none;
}

.DataBaseDetailText {
    font-family: var(--poppins-regular);
    color: var(--dark-grey3);
}

.anchor {
    cursor: pointer;
}

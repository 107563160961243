@import '/src/App.css';


.firstSignInContainer {
    display: flex;
    background-image: url('../../assets/images/fondo_base_violeta-.png');
    background-size: cover;
}

.firstSignInLSideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 90vh !important;
    width: 50vw !important;
    padding: 4vh 0;
}

.firstSignInTudatalogo {
    margin-top: 3vw;
    margin-left: 3vw;
    height: 9vh;
}

.firstSignInLSideText {
    font-family: var(--quicksand-bold);
    font-size: 3vw;
    color: var(--white);
    margin-left: 3vw;
    width: 62%;
}

.firstSignInLSideBackContainer {
    font-family: var(--quicksand-bold);
    font-size: 2vw;
    color: var(--white);
    margin-top: 2vw;
    margin-left: 3vw;
    margin-bottom: 2vw;
}

.firstSignInLSideBackLink,
.firstSignInLSideBackLink:hover {
    font-family: var(--poppins-medium);
    font-size: 3vh;
    color: var(--white);
    text-decoration: none;
    position: absolute;
    top: 92vh;
}

.firstSignInReturnArrow {
    width: 1.6vw;
    transform: rotate(90deg);
}

.firstSignInRSideContainer {
    background-color: var(--white);
    background-size: cover;
    height: 100vh !important;
    width: 50vw !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6vw;
}

.firstSignInRSideTopText {
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    color: var(--dark-grey2);
    line-height: 3vh;
    width: 100%;
}

.firstSignInRSideCodeMessage {
    font-family: var(--poppins-medium);
    font-size: 1.1vw;
    margin: 4vh 0 0.2vh 0;
    line-height: 3vh;
    width: 100%;
    user-select: none;
    color: var(--white);
}

.firstSignInRSideCorrectCode {
    color: var(--white);
}

.firstSignInRSideWrongCode {
    color: var(--red);
}

.firstSignInRSideCodeFormCOntainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
}

.firstSignInRSideCodeForm {
    width: 100%;
}

.firstSignInRSideInputsGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.firstSignInRSideInput {
    height: 14vh !important;
    width: 5.5vw !important;
    border: 1px solid var(--dark-grey2);
    border-radius: 15px !important;
    text-align: center !important;
    font-family: var(--poppins-light) !important;
    font-size: 5vh !important;
    color: var(--dark-grey) !important;
}

.firstSignInRsideCodeAdvice {
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    color: var(--dark-grey2);
    margin-top: 1vh;
}

.firstSignInRSideSendBtn,
.firstSignInRSideSendBtn:hover,
.firstSignInRSideSendBtn:focus {
    font-family: var(--poppins-bold);
    font-size: var(--button-font-size);
    border-radius: 13px;
    padding: 2vh 0;
    margin-top: 2vh;
    background-color: var(--orange);
    color: var(--white);
    border: 100px;
    width: 100%;
    text-decoration: none;
    text-align: center;
}

.firstSignInRSideSendBtn:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
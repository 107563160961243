.companyRegistration3Inputs {
    margin-top: 30vh;
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.companyRegistration2ContinueButton:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

input[type="password"]::-ms-reveal {
    display: none;
}
@import '/src/App.css';

.deleteAccountMainContainer {
    display: flex;
    flex-direction: column;
    height: 75vh;
    justify-content: space-between;
    padding: 2vw 4vw 0 4vw;
}

.deleteAccountH3 {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.5vw;
    margin-bottom: 2vh;
}

.deleteAccountSubTitle1,
.deleteAccountSubTitle2 {
    font-family: var(--poppins-medium);
    color: var(--dark-grey);
    font-size: 1.3vw;
}

.deleteAccountContent {
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    font-size: 1.2vw;
}

.deleteAccountButton {
    width: fit-content;
    align-self: flex-end;
}

.deleteAccountButton:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.deleteAccountOther {
    margin-right: 0.2vw;
}

.deleteAccountLink,
.deleteAccountLink:hover {
    text-decoration: none;
    color: var(--orange);
}

.deleteAccountChkBoxContainer1 {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 2px 6px 25px #00000029;
    border-radius: 35px;
    padding: 2vh 2vw;
}

.deleteAccountChkBoxSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vh 0;
}

.deleteAccountChkBoxContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3vh 0;
}

#deleteAccountOp1,
#deleteAccountOp2,
#deleteAccountOp3,
#deleteAccountOp4 {
    margin-right: 0.5vw;
    width: 28px;
    height: 28px;
}

.deleteAccountOption {
    margin-right: 0.5vw;
    width: 28px;
    height: 28px;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    border-radius: 5px;
}

.deleteAccountOption:checked[type=checkbox] {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e')
}

.deleteAccountOption:checked[type=radio] {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e')
}

.deleteAccountOption:checked {
    background-color: var(--dark-grey2);
    border-color: var(--dark-grey2);
}

.deleteAccountTextInput,
.deleteAccountTextInput:focus {
    height: 4vh;
    width: 50% !important;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 2px solid var(--dark-grey__transparent) !important;
    margin-left: 20px;
    font-family: var(--poppins-light) !important;
    color: var(--dark-grey) !important;
    font-size: 1.5vw !important;
}

.deleteAccountFooterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 2px 6px 25px #00000029;
    border: none;
    border-radius: 15px;
    background: var(--white);
    padding: 1vh 2vw;
    font-family: var(--poppins-light);
    color: var(--orange);
    font-size: 1vw;
    margin-left: 58vw;
}
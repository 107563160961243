.SubCategoriesListCategoryItem {
    background-color: var(--dark-purple);
    text-align: center;
    padding: 4vh 0 1vh;
    border: 1px solid var(--white);
    border-radius: 20px;
    height: 30vh;
    color: var(--white);
}

.SubCategoriesListCategoryItem:not(.isCheckedBackground):hover {
    background-color: var(--light-purple);
}

.SubCategoriesListCategoryItem img {
    width: 7vw;
    height: 14vh;
    margin-bottom: 3vh;
}

.SubCategoriesListCategoryItem p {
    font-size: 1.4vw;
}

.isCheckedBackground {
    background-color: var(--orange);
}
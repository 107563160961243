@import '/src/App.css';

.conditionsPoliciesSection1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.conditionsPoliciesMainImg {
    width: 18vw;
    border-radius: 35px;
}

.conditionsPoliciesSubContainer1 {
    width: 55vw;
    height: 30vh;
    margin-left: 1vw;
    margin-right: 4vw;
}

.conditionsPoliciesH3 {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.5vw;
}

.conditionsPoliciesParagraph {
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    font-size: 1.4vw;
}

.conditionsPoliciesSection2 {
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
}

.conditionsPoliciesDownLoadLink {
    text-decoration: none;
    color: var(--orange);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin: 1vh 0;
    width: fit-content;
}

.conditionsPoliciesDownLoadImg {
    width: 2.2vw;
    margin: 0 1vw 0 2vw;
}

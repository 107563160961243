@import '/src/App.css';

.contentUs {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../assets/images/fondo_general_naranja.png");
    background-size: cover;
}

.backgroundUs {
    margin-top: 8rem;
    background: linear-gradient(180deg, rgba(198, 42, 58, .8) 0%, rgba(161, 23, 78, .8) 100%);
    padding: 3vh 4vw 0;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    width: 65%;
    height: 68%;
}

.labelUs {
    font-family: var(--poppins-semibold);
    font-size: 1.3vw;
    color: var(--white);
    margin-bottom: 1.5vh;
}

.itemUs {
    background-color: var(--white);
    cursor: pointer;
    padding: 2vh 1vw;
    margin-bottom: 1.5vh;
    width: 22%;
    border-radius: 5px;
}

.itemUs h5 {
    font-family: var(--poppins-semibold);
    font-size: .8vw;
    margin: 0;
    color: var(--dark-grey4);
}

.itemUs p {
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    font-size: .8vw;
    line-height: 2vh;
}

.itemUs strong {
    font-family: var(--quicksand-bold);
}

.itemOpened {
    width: 100%;
}

.itemOpened h5 {
    margin-bottom: 1.5vh;
}

.contendAccordionUs {
    width: 50vw;
    max-height: 60vh;
    overflow-y: auto;
}

.accordionBody {
    background: var(--light-grey);
    width: 100%;
    font-family: var(--poppins-light);
    font-size: .7vw;
    color: var(--dark-grey3);
}

.accordionItem {
    background: var(--orange-transparent) !important;
    color: var(--black) !important;
    --bs-accordion-btn-focus-box-shadow: #fff;
    border: 1px solid var(--dark-grey) !important;
    border-bottom: 1px solid #fff !important;
}

.accordionItem .accordion-button {
    color: var(--dark-grey3) !important;
    background-color: var(--white);
    font-size: .9vw;
    font-family: var(--poppins-semibold);
}

.accordionItem .accordion-button::after {
    background-image: url(../../../../assets/icons/arrowDown.svg) !important;
}
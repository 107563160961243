@import '/src/App.css';

.newPqrsMainContainer {
    padding: 3vh 3vw;
}

.newPqrsH2 {
    font-family: var(--poppins-medium);
    color: var(--dark-purple);
    font-size: 1.5vw;
}

.newPqrsSubContainer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.newPqrsParagraphContainer {
    padding: 0 2vw 0 5vw;
}

.firstParagraph {
    margin-bottom: 3vh;
}

.newPqrsParagraph1, .newPqrsParagraph2 {
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    font-size: 1vw;
}

.newPqrsSubtitle {
    font-family: var(--poppins-bold);
    color: var(--dark-grey);
    font-size: 1vw;
}

.newPqrsTextArea {
    width: 100%;
    height: 20vh;
    resize: none;
    border: none;
    background: #F6F5F5 0% 0% no-repeat padding-box;
    font-family: var(--quicksand-regular);
    color: var(--dark-grey8);
    font-size: 1.5vw;
    box-shadow: inset 0px 3px 6px #00000029, 2px 6px 25px #00000029;
    border-radius: 9px;
    padding: 1vh 1vw;
}

.newPqrsTextAreaLengthCounter{
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    text-align: end;
}

.newPqrsModalHeader {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}

.newPqrsButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 4vh;
}

.newPqrsButtonStructure {
    font-size: var(--button-fontSize);
    font-family: var(--poppins-medium);
    border-radius: 0.8vw;
    padding: 0.5vw 8vw;
    text-decoration: none;
}

.newPqrsButton {
    border: none;
    background-color: var(--orange);
    color: var(--white);
    font-size: 1.5vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.newPqrsButton:disabled {
    background-color: var(--white);
    border: solid 1px var(--dark-grey3);
    color: var(--dark-grey2);
    font-size: 1.5vw;
    cursor: not-allowed;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.newPqrsButtonDisabled {
    background-color: var(--white);
    border: solid 1px var(--dark-grey3);
    color: var(--dark-grey2);
    cursor: not-allowed;
}

.newPqrsConsultationFirst {
    color: var(--white);
    font-family: var(--poppins-regular);
}

.newPqrsConsultationSecond {
    color: var(--white);
    font-family: var(--poppins-medium);
}

.requestTypeButton::after {
    content: none !important;
}

.arrowIcon {
    width: 1vw;
    transform: rotate(0deg);
    transition: transform 0.1s ease;
}

.arrowIconRotate {
    transform: rotate(180deg); 
}

.requestTypeButton,
.requestTypeButton:active,
.requestTypeButton:focus,
.requestTypeButton:hover {
    width: 20vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 3px 6px #00000029, 2px 6px 25px #00000029;
    border-radius: 9px;
    background-color: #F6F5F5;
    border: 0;
    font-family: var(--poppins-regular);
    color: var(--dark-grey);
    font-size: 5px;
    padding: 0.5vw 1.2vw;
}

.requestTypeDropdownMenu {
    width: 20vw;
    box-shadow: inset 0px 3px 6px #00000029, 2px 6px 25px #00000029;
    z-index: 1;
}

.selectType-option {
    box-shadow: inset 0px 3px 6px #00000029, 2px 6px 25px #00000029;
    padding: 3px 3px;
    background-color: var(--white);
    font-family: var(--poppins-medium);
    font-size: 5px;
    color: #3D3D3D;
    border-radius: 15px;
    height: 13px;
    width: 90%;
    margin: 0.3vh auto;
}

.selectType-option:hover {
    background-color: var(--orange);
    color: var(--white);
}

.hideObject {
    display: none;
}

@media (min-width: 640px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 7px;
    }

    .selectType-option {
        font-size: 7px;
        padding: 5.5px;
        height: 15px;
    }
}

@media (min-width: 854px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 8.5px;
    }

    .selectType-option {
        font-size: 10px;
        padding: 5px;
        height: 23px;
    }
}

@media (min-width: 1080px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 11px;
    }

    .selectType-option {
        font-size: 11px;
        padding: 9px 15px;
        height: 32px;
    }
}

@media (min-width: 1280px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 13px;
    }

    .selectType-option {
        font-size: 13px;
        padding: 11px 18px;
        height: 40px;
    }
}

@media (min-width: 1720px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 18px;
    }

    .selectType-option {
        font-size: 18px;
        padding: 11px 18px;
        height: 55px;
    }
}

@media (min-width: 1920px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 19px;
    }

    .selectType-option {
        font-size: 19px;
        padding: 20px 30px;
        height: 65px;
    }
}

@media (min-width: 2560px) {

    .requestTypeButton,
    .requestTypeButton:active,
    .requestTypeButton:focus,
    .requestTypeButton:hover {
        font-size: 25.5px;
    }

    .selectType-option {
        font-size: 25.5px;
        padding: 30px 40px;
        height: 95px;
    }
}

.optionSelected {
    background-color: var(--orange);
    color: var(--white);
}


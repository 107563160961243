#DataBaseChoose{
    background-image: url(../../../../assets/images/fondo_general_naranja.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.DataBaseChooseContent{
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.DataBaseChooseLogoBox img{
    width: 20vw;
}

.DataBaseChooseTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    margin-top: -3vh;
    margin-bottom: 2vh;
}

.DataBaseChooseTitle h1{
    font-size: 2.5vw;
    font-family: var(--quicksand-bold);
}

.DataBaseChooseTitle h4{
    font-size: 1.5vw;
    font-family: var(--poppins-light);
}

.DataBaseChooseCardsBox{
    display: flex;
    height: 50vh;
    gap: 5vw;
}

.DataBaseChooseCardsBox input{
    display: none;
}

.PlanSelected{
    border: 2px solid var(--white) !important; 
}

.DataBaseChooseLeftCard{
    background-color: var(--dark-purple);
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 5vh 4vw;
    width: 50%;
    border-radius: 20px;
}

.DataBaseChooseLeftCardImage{
    width: 50%;
}

.DataBaseChooseLeftCardImage img{
    width: 12vw;
}

.DataBaseChooseLeftCardTextTitles{
    margin-bottom: 4vh;
}

.DataBaseChooseLeftCardTextTitles h2:nth-child(1){
    font-family: var(--quicksand-regular);
    font-size: 2vw;
    margin: 0;
}

.DataBaseChooseLeftCardTextTitles h2:nth-child(2){
    font-size: 2vw;
    font-family: var(--poppins-bold);
}

.DataBaseChooseLeftCardText p{
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    width: 15vw;
    line-height: 2.8vh;

}

.DataBaseChooseRightCard{
    background-color: var(--light-purple);
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 5vh 4vw;
    width: 50%;
    border-radius: 20px;
}

.DataBaseChooseRightCardImage{
    width: 50%;
}

.DataBaseChooseRightCardImage img{
    width: 12vw;
}

.DataBaseChooseRightCardTextTitles{
    margin-bottom: 4vh;
}

.DataBaseChooseRightCardTextTitles h2:nth-child(1){
    font-family: var(--quicksand-regular);
    font-size: 2vw;
    margin: 0;
}

.DataBaseChooseRightCardTextTitles h2:nth-child(2){
    font-size: 2vw;
    font-family: var(--poppins-bold);
}

.DataBaseChooseRightCardText p{
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    width: 13vw;
    line-height: 2.8vh;
}

.DataBaseChooseFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.DataBaseChooseFooter a{
    text-decoration: none;
}

.DataBaseChooseFooterBack img{
    width: 2vw;
    transform: rotate(90deg);
    margin-right: .5vw;
}

.DataBaseChooseFooterBack span{
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--white);
}

.DataBaseChooseFooterButton button{
    width: 40vw;
    border: none;
    background-color: var(--orange);
    color: var(--white);
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    padding: 1vh 0;
    border-radius: 10px;
}

.DataBaseChooseFooterButton button:disabled{
    background-color: transparent;
    border: 0.01vw solid var(--white);
    cursor: not-allowed;
}

.DataBaseChooseFooterSkip img{
    width: 2vw;
    transform: rotate(-90deg);
    margin-left: .5vw;
}

.DataBaseChooseFooterSkip span{
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--white);
}

.DataBaseChooseFooter{
    margin-top: auto;
}
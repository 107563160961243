.grayCard {
  background-color: #E3E3E3;
  min-height: 25vh;
  border-radius: 2vw;
  padding: 2vw;
  position: relative;
  overflow: hidden;
}

.grayCardTitle {
  font-family: var(--quicksand-bold);
  color: var(--dark-purple);
  font-size: 2vw;
}

/* --- Children styles --- */

.grayCardActiveDataBasesRight1 {
  font-family: var(--quicksand-bold);
  font-size: 5vw;
  display: inline-block;
  width: 30%;
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 2.0vh;
  margin: 0 2vw;
  color: #6f6f6f;
}

.grayCardNavLinkRight1 {
  font-family: var(--quicksand-bold);
  color: var(--dark-purple);
  font-size: 2.2vw;
  text-decoration: none;
  display: inline-block;
  width: 45%;
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 2.0vh;
  margin: 0 2vw;
}

.grayCardImageRight1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.grayCardImageLeft1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.grayCardImageLeft2 {
  position: absolute;
  bottom: 0;
  left: 3vw;
}

.grayCardImageBottom {
  position: absolute;
  bottom: -9vh;
}

.grayCardParagraphFont1 {
  font-family: var(--poppins-light);
}

.grayCardParagraphSize1 {
  font-size: 1.6vw;
}

.grayCardParagraphSize2 {
  font-size: 1.0vw;
}

.grayCardParagraphColor1 {
  color: #6B6B6B;
}

.grayCardParagraphColor2 {
  color: var(--dark-grey);
}

.grayCardParagraphMarginTop1 {
  margin: 9% 0 0 0;
}

.grayCardDataBaseImage {
  height: 22vh;
}
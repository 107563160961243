@import '/src/App.css';

.customerSupportMainContainer {
    padding: 4vh 2vw;
}

.customerSupportTextInput {
    width: 33%;
    height: 4vh;
    border: none;
    border-bottom: 1px solid var(--dark-grey);
    font-size: 1.3vw;
    margin: auto;
    padding-left: 0.5vw;
}

.customerSupportTextInput::placeholder {
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    font-size: 1.3vw;
}

.customerSupportSearchIcon {
    width: 1.5vw;
    position: relative;
    right: 2vw;
    bottom: 5px;
}

.customerSupportSubContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customerSupportImgPContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.customerSupportInfoIcon {
    width: 1.3vw;
    padding: 3vh 0;
}

.customerSupportMainParagraph {
    font-family: var(--poppins-medium);
    color: var(--dark-grey);
    font-size: 1.2vw;
    padding: 2vh 3vw 5vh 1vw;
}

.customerSupportButton {
    width: max-content;
    background-color: var(--orange);
    color: var(--white);
    border: none;
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    border-radius: 0.8vw;
    padding: 1vw 12vw;
    text-decoration: none;
    margin-bottom: 5vh;
}

.customerSupportH2 {
    font-family: var(--poppins-medium);
    color: var(--dark-purple);
    font-size: 1.5vw;
}

.customerSupportAccordion {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    line-height: 4vh;
    color: var(--dark-grey);
    width: 100%;
    max-height: 33vh;
    overflow-y: auto;
    padding: 20px 15px 10px 15px;
    position: relative;
    right: 16px;
}

.customerSupportAccordion::-webkit-scrollbar {
    width: 15px;
}

.customerSupportAccordion::-webkit-scrollbar-track {
    background: var(--dark-grey);
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
}

.customerSupportAccordion::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey7);
    border-radius: 20px;
}

.customerSupportAccordionItem {  
    border-radius: 15px !important;
    margin-bottom: 5px;
}

.customerSupportAccordionItem .accordion-button {
    background-color: var(--white) !important;
    font-family: var(--poppins-medium);
    color: var(--dark-grey7);
    border-radius: 15px !important;
    -webkit-box-shadow: 0px 1px 16px 6px rgba(191,191,191,0.57); 
    box-shadow: 0px 1px 16px 6px rgba(191,191,191,0.57);
    font-size: 1.2vw;
    padding: 1.5vw;
}

.customerSupportAccordionItem .accordion-button::after {
    content: none;
}

.customerSupportAccordionItemContent {
    background: var(--light-grey4);
    width: 100%;
    font-family: var(--poppins-light);
    color: var(--dark-grey7);
    font-size: 1.05vw;
    line-height: 1.3vw;
    border-radius: 15px;
    border: solid 0.1vw var(--light-grey5);
}
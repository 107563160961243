.SubCategoriesItemsListCategoryItem{
    background-color: var(--dark-purple);  
    text-align: center;
    height: 10vh;
    border: 1px solid var(--white);
    border-radius: 3vh;
    color: var(--white);
}

.SubCategoriesItemsListCategoryItem img{
    width: 8vw;
    margin-bottom: 3vh;
}

.isCheckedBackground{
    background-color: var(--orange);
}

.shortText{
    font-size: 1.9vw;
}

.mediumText{
    font-size: 1.3vw;
}

.longText{
    font-size: 1.1vw;
}
#CategoriesGender {
    height: 100vh;
    background-image: url(../../../../assets/images/fondo_general_violeta.png);
    background-size: cover;
    width: 100%;
}

.CategoriesGenderContent {
    padding: 10vh 5vw 6vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.CategoriesGenderLogoBox img {
    width: 20vw;
}

.CategoriesGenderText {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.4vw;
    margin-bottom: 3vh;
    margin-top: -2vh;
}

.CategoriesGenderText p {
    width: 55%;
}

.CategoriesGenderGrid {
    width: 55%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh 1vw;
}

.CategoriesGenderCard {
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid var(--white);
    height: 26vh;
    position: relative;
    border-radius: 15px;
    background-color: var(--dark-purple);
}

.CategoriesGenderCard img {
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.CategoriesGenderCard span {
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1vw;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: .5vh 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.CategoriesGenderCard:hover span {
    background-color: var(--light-purple);
}

.CategoriesGenderFooter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin-top: auto;
}

.CategoriesGenderFooterContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CategoriesGenderFooterContent a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.CategoriesGenderFooterBackBtn {
    width: 1.5vw;
    rotate: -270deg;
    margin-left: .5vw;
}

.CategoriesGenderFooterNextBtn {
    background: none;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesGenderFooterNextBtn img {
    background: none;
    width: 1.5vw;
    rotate: -90deg;
    margin-left: .5vw;
}

.CategoriesGenderFooterNextBtn:disabled {
    background: none;
    cursor: not-allowed;
    color: var(--dark-grey);
    border: 0;
    display: flex;
    align-items: center;
}

.CategoriesGenderFooterNextBtn:disabled img {
    background: none;
    display: none;
}

.CategoriesGenderFooterContent div {
    display: flex;
    align-items: center;
}
#DataBasePrice {
    display: flex;
    background-image: url(../../../../assets/images/fondo_general_naranja.png);
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.DataBasePriceLSide {
    width: 50%;
    padding-left: 5vw;
    padding-top: 10vh;
    padding-bottom: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
}

.DataBasePriceRSide {
    background-color: var(--white);
    width: 50%;
    height: 100vh;
}

.DataBasePriceLSideTside img {
    width: 20vw;
    margin-bottom: 5vh;
}

.DataBasePriceLSideTside h2 {
    font-family: var(--quicksand-bold);
    font-size: 2.3vw;
    margin-bottom: 5vh;
}

.DataBasePriceLSideTside p {
    font-family: var(--poppins-light);
    font-size: 1.5vw;
}

.DataBaseSimpleLSideCard {
    display: flex;
    background-color: var(--dark-purple);
    width: 80%;
    padding: 2vh 2vw;
    border: 1px solid var(--white);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-left: -5.1vw;
    margin-bottom: 5vh;
}

.DataBaseSimpleLSideCardImgBox {
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DataBaseSimpleLSideCardImgBox img {
    width: 5vw;
}

.DataBaseSimpleLSideCardText {
    padding: 1vh 2vw;
}

.DataBaseSimpleLSideCardTitle {
    font-family: var(--quicksand-bold);
    font-size: 2.5vw;
    letter-spacing: -2px;
    margin-bottom: 0;
}

.DataBaseSimpleLSideCardSubtitle {
    font-family: var(--poppins-light);
    font-size: 1.5vw;
}

.DataBaseSpecializedLSideCard {
    display: flex;
    background-color: var(--light-purple);
    width: 80%;
    padding: 2vh 2vw;
    border: 1px solid var(--white);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-left: -5.1vw;
    margin-bottom: 5vh;
}

.DataBaseSpecializedLSideCardImgBox {
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DataBaseSpecializedLSideCardImgBox img {
    width: 5vw;
}

.DataBaseSpecializedLSideCardText {
    padding: 1vh 2vw;
}

.DataBaseSpecializedLSideCardTitle {
    font-family: var(--quicksand-bold);
    font-size: 2.5vw;
    letter-spacing: -2px;
    margin-bottom: 0;
}

.DataBaseSpecializedLSideCardSubtitle {
    font-family: var(--poppins-light);
    font-size: 1.5vw;
}

.DataBasePriceLSideBsideFooter {
    display: flex;
}

.DataBasePriceLSideBsideFooter a {
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.DataBasePriceLSideBsideFooter img {
    width: 2vw;
    transform: rotate(90deg);
}

.DataBasePriceLSideBsideFooter p {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.DataBasePriceRSideTopBox {
    background-color: var(--orange);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    width: 100%;
    padding: 2vh 2vw;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
}

.DataBasePriceRSide {
    padding: 20vh 10vh 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.DataBasePriceRSide img {
    width: 11vw;
}

.DataBasePriceRSideTopBoxMonths {
    font-family: var(--quicksand-bold);
    font-size: 2.7vw;
    letter-spacing: -2px;
}

.DataBasePriceSimpleRSideCard {
    background-color: var(--dark-purple);
    color: var(--white);
    display: flex;
    align-items: center;
    height: 100%;
    margin: 2vh 0;
    padding: 3vw;
    border-radius: 20px;
}

.DataBasePriceSimpleRSideCardTop {
    line-height: 4vh;
    margin-bottom: 1vh;
}

.DataBasePriceSimpleRSideCardSubtitle {
    font-family: var(--poppins-bold);
}

.DataBasePriceSimpleRSideCardBottom {
    line-height: 3.8vh;
}

.DataBasePriceSimpleRSideCardText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--poppins-light);
    font-size: 1.7vw;
    padding: 2vw;
    height: 100%;
}

.DataBasePriceSpecializedRSideCard {
    background-color: var(--light-purple);
    color: var(--white);
    display: flex;
    align-items: center;
    height: 100%;
    margin: 2vh 0;
    padding: 3vw;
    border-radius: 20px;
}

.DataBasePriceSpecializedRSideCardTop {
    line-height: 4.5vh;
    margin-bottom: 1vh;
}

.DataBasePriceSpecializedRSideCardSubtitle {
    font-family: var(--poppins-bold);
    font-size: 1.9vw;
}

.DataBasePriceSpecializedRSideCardBottom {
    font-size: 1.5vw;
    line-height: 3vh;
    width: 15vw;
    margin-bottom: 2vh;
}

.DataBasePriceSpecializedRSideCardText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--poppins-light);
    font-size: 1.7vw;
    padding: 2vw;
    height: 100%;
}

.DataBasePriceRSideButton {
    background-color: var(--orange);
    border: 0;
    color: var(--white);
    width: 100%;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 0;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
}

.DataBasePriceRSideButton:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
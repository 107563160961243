.buyDBSelectUsersQuantity {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2vw;
}

.buyDBSelectUsersLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buyDBSelectUsersTitle {
  font-family: var(--poppins-bold);
  color: var(--dark-purple);
  font-size: 1.5vw;
  margin-bottom: 3vh;
}

.buyDBSelectUsersLeftDescription {
  margin-bottom: 4vh;
}

.buyDBSelectUsersLeftDescription,
.buyDBSelectUsersAvailability {
  font-family: var(--poppins-regular);
  font-size: 1.5vw;
  color: #5E5E5E;
  width: 85%;
  line-height: 3.7vh;
}

.buyDBSelectUsersAvailableUsers {
  color: var(--orange);
  font-family: var(--poppins-bold);
}

.buyDBSelectUsersRight {
  padding: 0 5vw;
}

.buyDBSelectUsersRightSubcontainer {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.buyDBSelectUsersQuantityInput {
  border-radius: 7px;
  border: none;
  background-color: #F6F5F5;
  box-shadow: inset 0px 2px 5px 0px rgba(194, 194, 194, 1);
  height: 8vh;
  padding: 0 1vw;
  width: 100%;
  font-family: var(--poppins-regular);
  color: #5E5E5E;
}

.buyDBSelectUsersQuantityInput::placeholder {
  font-family: var(--poppins-regular);
  color: #AFAFAF;
  font-size: 1vw;
}

.buyDBSelectUsersNextButton {
  min-height: 7vh;
  background-color: var(--orange);
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--poppins-medium);
  text-decoration: none;
  color: var(--white);
  font-size: var(--button-font-size);
  width: 80%;
}

.buyDBSelectUsersNextButtonDisabled {
  cursor: not-allowed;
  opacity: 0.3;
  min-height: 7vh;
  background-color: var(--orange);
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--poppins-medium);
  text-decoration: none;
  color: var(--white);
  font-size: var(--button-font-size);
  width: 80%;
}

.buyDBSelectUsersNextButtonDisabled,
.buyDBSelectUsersNextButtonDisabled:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.buyDBFinalResumeModalContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyDBFinalResumeModalContentInput {
  width: 2vw;
  height: 2vw;
  margin: 0 1vw;
}

.buyDBFinalResumeModalContentSpan {
  font-family: var(--poppins-medium);
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
.successfulRegister {
    height: 100vh;
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
    padding: 10vh 5vw;
    color: var(--white);
  }
  
  .successfulRegisterLogo {
    height: 10vh;
    margin-bottom: 10vh;
  }
  
  .successfulRegisterPrincipalContent {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
  }
  
  .successfulRegisterPrincipalMessage {
    backdrop-filter: blur(20px);
    padding: 5vh 0;
    margin: 0 -5vw;
    border-top: solid 1px var(--white);
    border-bottom: solid 1px var(--white);
  }
  
  .successfulRegisterTitle {
    font-family: var(--quicksand-bold);
    font-size: 5vw;
  }
  
  .successfulRegisterPurchasedPlan {
    font-family: var(--quicksand-bold);
    font-size: 2vw;
  }
  
  .successfulRegisterSecondaryMessage {
    font-family: var(--poppins-light);
    font-size: 1.5vw;
    width: 35%;
    line-height: 4vh;
    margin: 0 auto;
  }
  
  .successfulRegisterTuData {
    font-family: var(--poppins-medium);
  }
  
  .successfulRegisterNextButton {
    margin: 0 auto;
    margin-bottom: 10vh;
    padding: 1.5vh 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--white);
    background-color: var(--orange);
    border: none;
    width: 40%;
    border-radius: 1vw;
  }
@import '/src/App.css';

.logInMainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin: 0;
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
    overflow: hidden;
}

.logInSubContainer1 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;
    padding: 4vh 3vw;
    justify-content: space-between;
}

.logInSubContainer1Logo {
    height: 10vh;
    margin-top: 8vh;
    align-self: flex-start;
}

.logInSubContainer1H2 {
    width: 40vw;
    font-family: var(--quicksand-bold);
    font-size: 3vw;
    color: var(--white);
}

.returnArrow {
    width: 1.6vw;
    transform: rotate(90deg);
}

.logInReturnParagraph,
.logInReturnParagraph:hover {
    font-family: var(--poppins-medium);
    font-size: 3vh;
    color: var(--white);
    text-decoration: none;
}

.logInSubContainer2 {
    background-color: var(--white);
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;
    padding: 15vh 8vw;
    justify-content: space-between;
}

.login-col2__title1 {
    font-family: var(--poppins-bold);
    font-size: 6vh;
}

.login-col2__title2 {
    font-family: var(--poppins-light);
    font-size: 2.5vh;
    margin-bottom: 7vh;
    color: var(--dark-grey);
}

.logInSubContainer2Form1,
.logInSubContainer2Form2 {
    border: none;
    border-radius: 0.5vw;
    border: 1px solid var(--dark-grey3);
    margin-bottom: 1vh;
    height: 6vh !important;
    padding-top: 1.65rem !important;
    padding-bottom: 0.5rem !important;
}

.showPassButtonLogIn {
    position: absolute;
    display: flex;
    align-items: center;
    width: 2vw;
    height: 6.5vh;
    right: 1vw;
    top: 1px;
}

.logInEyeIcon {
    width: 2vw;
}

.logInRememberContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 4vh;
}

.logInRememberSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logInRecordarChk {
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 5px !important;
    border: 1px solid var(--dark-grey);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.logInRecordarChk:checked {
    background-color: var(--dark-grey2);
    border-color: var(--dark-grey2);
}

.logInRecordarLbl {
    font-family: var(--poppins-light);
    font-size: 1.2vw;
    color: var(--dark-grey);
    margin-left: 1vw;
}

.logInRememberContainerLnk {
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
    text-decoration-line: none;
    text-decoration-style: none;
    color: var(--dark-grey);
}

.logInBtnContinuar {
    font-family: var(--poppins-medium);
    font-size: 1.6vw;
    border-radius: 13px;
    padding: 1vh 0;
    margin-top: 8vh;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    background-color: var(--orange);
    color: var(--white);
    border: 100px;
    width: 100%;
}

.logInBtnContinuar:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.logInParagraph {
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    margin-top: 15vh;
    margin-bottom: -9vh;
}

.logInPargraphLink {
    font-family: var(--poppins-bold);
    font-size: 1.3vw;
    color: var(--light-purple);
    text-decoration-line: none;
    text-decoration-style: none;
    cursor: pointer;
}

.showSpanPass {
    display: none;
}

.incorrectFormat {
    color: var(--red);
    font-size: 0.8vw;
    margin-bottom: 1vh;
    user-select: none;
    height: .3vh;
    font-family: var(--poppins-light);
}

input[type="password"]::-ms-reveal {
    display: none;
}